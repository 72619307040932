import React from 'react';
// import { Carousel } from 'react-bootstrap';
import Kin from './images/card/w91.png';
import Kin1 from './images/card/w101.jpg';
import Re from './images/card/w71.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function MyCarousel() {



    return (


        <div id="demo" className="carousel slide" data-bs-ride="carousel" >


            <div className="carousel-indicators">
                <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
            </div>


            <div className="carousel-inner" >
                <div className="carousel-item active" id='page' >
                    <img src={Kin} alt="Los Angeles" class="d-block" style={{ width: '100%' }} />
                    <div className="hero-text">
                        <h6>Znajdź swój wymarzony dom z pewnością siebie</h6>
                         
 
  <button className="btn btn-md btn-warning " type="button">
  <Link to="/contact" className="text-white text-decoration-none">Znajdź swój wymarzony dom </Link>
</button>


                    </div>
                </div>
                <div className="carousel-item" id='page'>
                    <img src={Kin1} alt="Chicago" class="d-block" style={{ width: '100%' }} />
                    <div className="hero-text">
                        <h6>Podróż bohatera do własności domu</h6>
                        
                         <button className="btn btn-md btn-warning " type="button">
  <Link to="/contact" className="text-white text-decoration-none">Znajdź swój wymarzony dom </Link>
</button>
                    </div>
                </div>
                <div className="carousel-item" id='page'>
                    <img src={Re} alt="New York" class="d-block" style={{ width: '100%' }} />
                    <div className="hero-text">
                        <h6>Bohaterowie witają w domu</h6>
                         
                         <button className="btn btn-md btn-warning " type="button">
  <Link to="/contact" className="text-white text-decoration-none">Znajdź swój wymarzony dom </Link>
</button>
                    </div>
                </div>
            </div>


            <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
            </button>
        </div>


    );
};

export default MyCarousel;
