import React from 'react';
import { Carousel } from 'react-bootstrap';

import W9 from './images/w9/w9.jpg';
import W91 from './images/w9/w91.jpg';
import W92 from './images/w9/w92.jpg';
import W93 from './images/w9/w93.webp';
import W94 from './images/w9/w94.webp';

import W95 from './images/w9/w95.webp';
import W96 from './images/w9/w96.webp';
import W97 from './images/w9/w97.webp';
import W98 from './images/w9/w98.webp';
import W99 from './images/w9/w99.webp';


import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail7() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Mieszkanie, ul. Żernicka </h1>
                <p>Żernicka, Żerniki, Wrocław, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W9}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W91}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W92}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W93}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W94}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W95}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W96}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W97}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W98}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W99}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    

                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane: 27 marca 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 885 000 zł</span></strong></p>
                    <p>Obszar: <strong><span style={{ color: 'brown' }}>  68,30 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}>   12 957,54 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Węgliniecka, Maślice, Wrocław, dolnośląskie
</span></strong></p>
                    <p>Piętro: <strong>parter/2</strong></p>
                    <p>Nie zwlekaj z kontakte:<strong>mbardzo wysokie zainteresowanie</strong></p>
                    <p>Liczba pokoi: <strong>3</strong></p>
                    <p>Rok budowy: <strong>2020</strong></p>
                    <p>Miejsce parkingowe: <strong>w garażu podziemnym</strong></p>
                    <p>Stan mieszkania:<strong>bardzo dobry</strong></p>


                </div>

            </section>
            
        </div>
        <Footer />
    </>
    );
}