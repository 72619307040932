import React from 'react';
import { Carousel } from 'react-bootstrap';
import W10 from './images/w10/w10.jpg';
import W11 from './images/w10/w11.jpg';
import W12 from './images/w10/w12.jpg';

import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail8() {
    return (<>

    <Navbar />

        <div>
         
            <section className='house1'>
               
                <h1 className="hh" style={{ paddingLeft: '40px' }}>Działka rolna Ścinawa</h1>
                <p>Ścinawa, dolnośląskie
</p>
                
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W10}
                        alt="First slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W11}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W12}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                
            </Carousel>
            <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane: 18 stycznia 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 56 160 zł </span> </strong></p>
                    <p>Obszar:<strong> <span style={{ color: 'brown' }}> 3,90 zł/m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}> 14 400 m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Działka rolna Ścinawa</span></strong></p>
                    <p>Warto zadzwonić: <strong>wysokie zainteresowanie</strong></p>
                    <p>Rodzaj działki: <strong>rolna</strong></p>
                    <p>Dojazd: <strong>droga nieutwardzona</strong></p>
                    <p>Kształt: <strong>nieregularny</strong></p>
                    <p>Media:<strong> ___</strong></p>
                </div>

            </section>
           
        </div>
        <Footer />
        </>
    );
}