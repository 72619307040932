import React, { Component } from 'react';
import axios from 'axios';
import BookItem from './BookItem';

export class Books extends Component {
  state = {
    books: [],
    isLoaded: false
  }

  componentDidMount() {
    axios.get('https://west2495.wordpress.com/wp-json/wp/v2/posts/')
    .then(res => this.setState({
        books: res.data,
        isLoaded: true
      }))
    .catch(err => console.log(err))
  }
render() {
  const { books, isLoaded } = this.state;
  return (
    <div>
      {books.map(book =>
        <BookItem key={book.id} book={book} />
      )}
    </div>
  );
}
}

export default Books;