import React, { useEffect } from 'react';
import Footer from './footer';
import HeroSlider from './imageSection';
import Navbar from './Navbar';
import ImageCard from './ResponsiveCard';
import Card1 from './card2';
import AOS from 'aos'
import 'aos/dist/aos.css';

function Home() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    return (
        <>
            <Navbar />
            <div>
                <HeroSlider />

                <section className="box1" data-aos="fade-up">
                    <div className="container1">
                        <div className="box">
                            <h2 style={{ textAlign: 'center', color: '#244563' }}>Witamy</h2>
                            <p style={{ paddingLeft: 10, paddingRight: 10 }}>Witamy w West24  Usługi związane z nieruchomościami! Cieszę się, że zdecydowałeś się odwiedzić moją stronę internetową i dowiedzieć się więcej o tym, jak mogę pomóc Ci kupić lub sprzedać dom. Dzięki wieloletniemu doświadczeniu w branży nieruchomości posiadam wiedzę i doświadczenie, aby Twoja następna transakcja nieruchomości zakończyła się sukcesem. Jestem dumny z zapewniania spersonalizowanej obsługi i uwagi każdemu klientowi.</p>
                        </div>
                        <div className="box">
                            <h2 style={{ textAlign: 'center', color: '#244563' }}>Misją</h2>
                            <p style={{ paddingLeft: 10, paddingRight: 10 }}>Naszą misją jest umożliwienie kupującym i sprzedającym domów korzystania z porad ekspertów, innowacyjnych narzędzi i wyjątkowej obsługi, łącząc ich z oddanymi agentami nieruchomości, aby ułatwić płynne i satysfakcjonujące transakcje dotyczące nieruchomości. Ta misja podkreśla zaangażowanie firmy w zadowolenie klientów!</p>
                        </div>
                        <div className="box">
                            <h2 style={{ textAlign: 'center', color: '#244563' }}>Kim jesteśmy</h2>
                            <p style={{ paddingLeft: 10, paddingRight: 10 }}>Jesteśmy zespołem oddanych i oświadczonych profesjonalistów z branży nieruchomości, którzy z pasją pomagają naszym klientom osiągnąć ich cele związane z nieruchomościami. Dzięki wieloletniemu doświadczeniu w branży posiadamy wiedzę i doświadczenie, aby poprowadzić Cię przez każdy etap procesu kupna lub sprzedaży.</p>
                        </div>
                    </div>
                </section>

                <section style={{ backgroundColor: '#000028' }} data-aos="fade-up">
                    <h5 style={{ textAlign: 'center',color: '#ddd' }}>Ekspert ds. nieruchomości w Województwie Dolnośląskim</h5>
                    <ImageCard />
                </section>

                <section class="testimonials" data-aos="fade-up">
                    <h2 style={{ color: '#000000', paddingBottom: '10px' }}>Co powiedzieli nasi szanowni klienci:</h2>
                    <div class="testimonial-card">
                        <blockquote>
                            "Agnieszka była przyjazna, profesjonalna i miła. Odpowiadała na zapytania w odpowiednim czasie i poleciłbym jej zespół każdemu, kto chce kupić lub sprzedać nieruchomość. Zdecydowanie 5-gwiazdkowe doświadczenie."
        </blockquote>
                        <p class="author">Amanuel</p>
                    </div>
                    <div class="testimonial-card">
                        <blockquote>
                            "Świetny agent! Dziękuję Agnieszko za pomoc w znalezieniu domu na zawsze."
        </blockquote>
                        <p class="author">Iwona</p>
                    </div>

                </section>

                <section style={{ backgroundColor: ' #000020' }} data-aos="fade-up">
                    <Card1 />
                </section>

               
            </div>
            <Footer />
        </>
    );
}

export default Home;