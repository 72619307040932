import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Navbar from './Navbar';
import Footer from './footer';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import GoogleMap from './GoogleMap';
import image from './images/card/aga1.jpg';
// import a from './images/card/contact.avif';

const ContactUs = () => {
  return (
    <>
      <Navbar />

      {/* <section id="ab" className="responsive-section">
  <img src={a} alt="About Us" className="responsive-image" />
  <div className="text-overlay" >
    <p className="pt">Jesteśmy do Twojej dyspozycji od 2014 roku</p>
  </div>
</section> */}
      <div className="pt-100">
        <h4 className="text-center" style={{color: '#ddd'}}>Skontaktuj się z nami</h4>
        <p className="px-60"  style={{color: '#ddd'}}>
          {/* Dziękujemy za odwiedzenie naszej strony internetowej! Jesteśmy tutaj, aby pomóc we wszystkich potrzebach związanych z nieruchomościami. Wypełnij poniższy formularz lub skontaktuj się z nami telefonicznie lub mailowo. Czekamy na wiadomość od Ciebie! */}
        Skontaktuj się z nami
Dziękujemy za odwiedzenie naszej strony internetowej! Jesteśmy tutaj, aby pomóc we wszystkich potrzebach związanych z nieruchomościami.  Czekamy na wiadomość od Ciebie!
        
        </p>
        <Container className="mt-5">
          <Row>
            <Col md={4}>
              {/* <ContactForm /> */}
              <ContactInfo />
            </Col>
            <Col md={4} id='image'>
              
               <img src={image} alt="About Us" className="img-fluid float-right" id='i'/>
            </Col>
          </Row>
        </Container>
        <GoogleMap />
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;