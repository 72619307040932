import React from 'react';
import { Link } from 'react-router-dom';
import HouseDetail1 from './Krzyki';

 import HouseDetail2 from './DomKryniczno';
// import HouseDetail3 from './HouseDetail3';
import Kin from './images/card/wd.jpg';
import W3 from './images/card/w30.webp';
import W4 from './images/card/w5.jpg';
import W5 from './images/card/w6.jpg';
import W6 from './images/card/w7.webp';
import W8 from './images/card/w8.webp';
import W16 from './images/card/w16.jpg';
import W17 from './images/card/w17.jpg';
import W18 from './images/card/w18.jpg';
import W19 from './images/card/w19.jpg';
import W20 from './images/card/w20.jpg';
import W21 from './images/card/w9.webp';
import W22 from './images/card/W26.jpg';
import W23 from './images/card/w30.jpg';
import W24 from './images/card/w28.jpg';

export default function Card1() {
    return (
        <section className="box1" id='box1' style={{ backgroundColor: '#000020' }}>
                            <h5 style={{ textAlign: 'center',color: '#ddd' }}>Prezentacja dolnośląskich domów: Oferty z zaufanym agentem nieruchomości.</h5>

             <div className="container1" style={{paddingTop: '50px'}}>
                <div className="box6">
                    <Link to="/DomKryniczno" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={Kin}
                            alt="Los Angeles"
                            class="d-block"
                            style={{ width: '100%' }}
                        />
                        <p>Dom Kryniczno <br/> <span style={{ marginLeft: '10px', color: 'red' }}>849 000 zł</span>
                        <span style={{ marginLeft: '10px', color: 'blue' }}>100 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 8 490 zł/m²</span></p></Link>
                    
                </div>
                <div className="box6">
                    <Link to="/Krzyki" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W3}
                            alt="Los Angeles"
                            class="d-block"
                            style={{ width: '100%' }}
                        />
                        <p >Mieszkanie Wrocław Krzyki, Wrocław, dolnośląskie <br/>
                        <span style={{ marginLeft: '10px', color: 'red' }}>895 300 zł</span>
                        <span style={{ marginLeft: '10px', color: 'blue' }}>70 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 12 790 zł/m²</span></p>
                    </Link>
                </div>
                <div className="box6">
                    <Link to="/Trzebnica" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W4}
                            alt="Los Angeles"
                            class="d-block"
                            style={{ width: '100%' }}
                        />
                        <p>Dom Trzebnica Trzebnica, dolnośląskie <br/> <span style={{ marginLeft: '10px', color: 'red' }}>449 000 zł</span>
                        <span style={{ marginLeft: '10px', color: 'blue' }}>100 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}>  4 490 zł/m²</span></p>
                    </Link>
                </div>
            </div>
            < div className="container1">

                <div className="box6">
                    <Link to="/Milicka" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W5}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',


                            }} />
                        <p>Dom, ul. Milicka
Milicka, Trzebnica, dolnośląskie <br/> <span style={{ marginLeft: '10px', color: 'red' }}> 850 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>160 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 5 312,50 zł/m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/Żernicka" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W8}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',

                            }} />
                        <p>Mieszkanie, ul. Żernicka
Żernicka, Żerniki, Wrocław, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}>559 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>40,40 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 13 836,63 zł/m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/Błękitna" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W6}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%'
                            }} />
                        <p>Działka budowlana, ul. Błękitna
Błękitna, Oleszna, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}> 160 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>1 500 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 106,67 zł/m²</span></p> </Link>
                </div>
            </div>
            < div className="container1">

                <div className="box6">
                    <Link to="/HouseDetail7" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W16}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',


                            }} />
                        <p>Mieszkanie, ul. Potokowa
Potokowa, Maślice, Wrocław, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}> 885 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>68,30 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 12 957,54 zł/m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/Kryniczno" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W17}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',

                            }} />
                        <p>Działka rolna Ścinawa
    
Ścinawa, dolnośląskie <br/><span style={{ marginLeft: '10px', color: 'red' }}>56 160 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>3,90 zł/m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 14 400 m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/Działkabudowlana" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W18}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%'
                            }} />
                        <p>Działka budowlana, ul. Wrocławska
Wrocławska, Nowa Wieś Wrocławska, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}>500 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}> 277,78 zł/m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 1 800 m²</span></p> </Link>
                </div>
            </div>
            < div className="container1">

                <div className="box6">
                    <Link to="/KrzykiWrocław" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W19}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',


                            }} />
                        <p>Mieszkanie, ul. Potokowa
Potokowa, Maślice, Wrocław, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}>2 950 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>  320 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}>9 218,75 zł/m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/Ceglana" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W20}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',

                            }} />
                        <p>Działka, ul. Ceglana, Swojczyce, Wrocław, dolnośląskie <br/><span style={{ marginLeft: '10px', color: 'red' }}>4 385 100 zł</span>
                        <span style={{ marginLeft: '10px', color: 'blue' }}>  14 617 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 300 zł/m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/OfiarOświęcimskich" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W21}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%'
                            }} />
                        <p>Biuro, ul. Ofiar Oświęcimskich
Ofiar Oświęcimskich, Stare Miasto, Wrocław, dolnośląskie <br/><span style={{ marginLeft: '10px', color: 'red' }}>11 550 zł</span> 
<span style={{ marginLeft: '10px', color: 'blue' }}> 165 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 70 zł/m²</span></p> </Link>
                </div>
            </div>  
            < div className="container1">

                <div className="box6">
                    <Link to="/KarłowiceWrocław" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W22}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',


                            }} />
                        <p>Dom Wrocław
Karłowice, Wrocław, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}>2 100 000 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>  250 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}> 8 400 zł/m²</span></p> </Link>
                </div>

                <div className="box6">
                    <Link to="/Potokowa" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W23}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%',


                            }} />
                        <p>Mieszkanie, ul. Potokowa
Potokowa, Maślice, Wrocław, dolnośląskie <span style={{ marginLeft: '10px', color: 'red' }}>649 999 zł</span>
<span style={{ marginLeft: '10px', color: 'blue' }}>46,78 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}>13 894,81 zł/m²</span>
      </p> </Link>
                </div>


                <div className="box6">
                    <Link to="/KrzykiWrocław2" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W24}
                            alt="Los Angeles"
                            class="d-block"
                            style={{
                                width: '100%'
                            }} />
                        <p>Dom Wrocław
Krzyki, Wrocław, dolnośląskie <br/><span style={{ marginLeft: '10px', color: 'red' }}>2 290 000 zł</span>
      <span style={{ marginLeft: '10px', color: 'blue' }}>280 m²</span>
      <span style={{ marginLeft: '10px', color: 'green' }}>8 178,57 zł/m²</span></p> </Link>
                </div>
            </div>  
            
            
        </section>
    );
}