import React from 'react';
import { Carousel } from 'react-bootstrap';

import W1 from './images/w14/w1.webp';
import W2 from './images/w14/w2.webp';
import W3 from './images/w14/w3.webp';
import W4 from './images/w14/w4.webp';
import W5 from './images/w14/w5.webp';

import W6 from './images/w14/w6.webp';
import W7 from './images/w14/w7.webp';
import W8 from './images/w14/w8.webp';
import W9 from './images/w14/w9.webp';
import W10 from './images/w14/w10.webp';
import W11 from './images/w14/w11.webp';
import W12 from './images/w14/w12.webp';
import W13 from './images/w14/w13.webp';



import Navbar from './Navbar';
import Footer from './footer';


export default function OfiarOświęcimskich() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Biuro, ul. Ofiar Oświęcimskich </h1>
                <p>Ofiar Oświęcimskich, Stare Miasto, Wrocław, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W1}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W2}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W3}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W4}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W5}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W6}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W7}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W8}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W9}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W10}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W11}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W12}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W13}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    


                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane: 30 kwietnia 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 11 550 zł</span></strong></p>
                    <p>Obszar: <strong><span style={{ color: 'brown' }}>  165 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}>   70 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}>Ofiar Oświęcimskich, Stare Miasto, Wrocław, dolnośląskie
</span></strong></p>
                    <p>Przeznaczenie:<strong> biurowe</strong></p>
                    <p>Warto zadzwonić: <strong>Wysokie zainteresowanie</strong></p>
                    <p>Pomieszczenia: <strong>6</strong></p>
                    <p>Parking: <strong>parking strzeżony w pobliżu</strong></p>
                    <p>Rodzaj wejścia: <strong>z klatki schodowej</strong></p>
                    <p>
                        Rodzaj budynku:<strong>biurowiec</strong></p>
                    



                </div>

            </section>

        </div>
        <Footer />
    </>
    );
}