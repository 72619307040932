import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './pages/Navbar';
import Footer from './pages/footer';

// Sample images for demonstration
import W4 from './pages/images/card/w5.jpg';
import Kin from './pages/images/card/wd.jpg';
import W5 from './pages/images/card/w6.jpg';
import W6 from './pages/images/card/w7.webp';
import W8 from './pages/images/card/w8.webp';
import W16 from './pages/images/card/w16.jpg';
import W17 from './pages/images/card/w17.jpg';
import W18 from './pages/images/card/w18.jpg';
import W19 from './pages/images/card/w19.jpg';
import W20 from './pages/images/card/w20.jpg';
import W21 from './pages/images/card/w9.webp';
import W22 from './pages/images/card/W26.jpg';
import W23 from './pages/images/card/w30.jpg';
import W24 from './pages/images/card/w28.jpg';
import W3 from './pages/images/card/w30.webp';

const AdminPage = () => {
    const [items, setItems] = useState([
        { id: 1, name: 'Dom Kryniczno', price: '849 000 zł', size: '100 m²', pricePerSize: '8 490 zł/m²', image: Kin, link: '/DomKryniczno' },
        { id: 2, name: 'Mieszkanie Wrocław Krzyki', price: '895 300 zł', size: '70 m²', pricePerSize: '12 790 zł/m²', image: W3, link: '/Krzyki' },
        { id: 3, name: 'Dom Trzebnica', price: '449 000 zł', size: '100 m²', pricePerSize: '4 490 zł/m²', image: W4, link: '/Trzebnica' },
        { id: 4, name: 'Dom ul. Milicka', price: '850 000 zł', size: '160 m²', pricePerSize: '5 312,50 zł/m²', image: W5, link: '/Milicka' },
        { id: 5, name: 'Mieszkanie ul. Żernicka', price: '559 000 zł', size: '40,40 m²', pricePerSize: '13 836,63 zł/m²', image: W8, link: '/Żernicka' },
        { id: 6, name: 'Działka budowlana ul. Błękitna', price: '160 000 zł', size: '1 500 m²', pricePerSize: '106,67 zł/m²', image: W6, link: '/Błękitna' },
        { id: 7, name: 'Mieszkanie ul. Potokowa', price: '885 000 zł', size: '68,30 m²', pricePerSize: '12 957,54 zł/m²', image: W16, link: '/Potokowa' },
        { id: 8, name: 'Działka rolna Ścinawa', price: '56 160 zł', size: '14 400 m²', pricePerSize: '3,90 zł/m²', image: W17, link: '/Kryniczno' },
        { id: 9, name: 'Działka budowlana ul. Wrocławska', price: '500 000 zł', size: '1 800 m²', pricePerSize: '277,78 zł/m²', image: W18, link: '/Wrocławska' },
        { id: 10, name: 'Mieszkanie ul. Potokowa', price: '2 950 000 zł', size: '320 m²', pricePerSize: '9 218,75 zł/m²', image: W19, link: '/Potokowa2' },
        { id: 11, name: 'Działka ul. Ceglana', price: '4 385 100 zł', size: '14 617 m²', pricePerSize: '300 zł/m²', image: W20, link: '/Ceglana' },
        { id: 12, name: 'Biuro ul. Ofiar Oświęcimskich', price: '11 550 zł', size: '165 m²', pricePerSize: '70 zł/m²', image: W21, link: '/OfiarOświęcimskich' },
        { id: 13, name: 'Dom Wrocław Karłowice', price: '2 100 000 zł', size: '250 m²', pricePerSize: '8 400 zł/m²', image: W22, link: '/KarłowiceWrocław' },
        { id: 14, name: 'Mieszkanie ul. Potokowa', price: '649 999 zł', size: '46,78 m²', pricePerSize: '13 894,81 zł/m²', image: W23, link: '/Potokowa3' },
        { id: 15, name: 'Dom Wrocław Krzyki', price: '2 290 000 zł', size: '280 m²', pricePerSize: '8 178,57 zł/m²', image: W24, link: '/KrzykiWrocław2' }
    ]);

    const [form, setForm] = useState({ name: '', price: '', size: '', pricePerSize: '', image: '', link: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleAddItem = () => {
        setItems([...items, { ...form, id: items.length + 1 }]);
        setForm({ name: '', price: '', size: '', pricePerSize: '', image: '', link: '' });
    };

    const handleDeleteItem = (id) => {
        setItems(items.filter(item => item.id !== id));
    };

    const handleUpdateItem = (id) => {
        const updatedItems = items.map(item => item.id === id ? { ...item, ...form } : item);
        setItems(updatedItems);
        setForm({ name: '', price: '', size: '', pricePerSize: '', image: '', link: '' });
    };

    return (
        <>
            <Navbar />
            <section style={{ backgroundColor: '#f0f0f0', padding: '20px' }}>
                <h2>Admin Page</h2>
                <div>
                    <h3>Add New Item</h3>
                    <input type="text" name="name" placeholder="Name" value={form.name} onChange={handleInputChange} />
                    <input type="text" name="price" placeholder="Price" value={form.price} onChange={handleInputChange} />
                    <input type="text" name="size" placeholder="Size" value={form.size} onChange={handleInputChange} />
                    <input type="text" name="pricePerSize" placeholder="Price Per Size" value={form.pricePerSize} onChange={handleInputChange} />
                    <input type="text" name="image" placeholder="Image Path" value={form.image} onChange={handleInputChange} />
                    <input type="text" name="link" placeholder="Link" value={form.link} onChange={handleInputChange} />
                    <button onClick={handleAddItem}>Add Item</button>
                </div>
                <div>
                    <h3>Existing Items</h3>
                    {items.map(item => (
                        <div key={item.id} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc' }}>
                            <p>{item.name} - {item.price} - {item.size} - {item.pricePerSize}</p>
                            <img src={item.image} alt={item.name} style={{ width: '100px' }} />
                            <div>
                                <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
                                <button onClick={() => handleUpdateItem(item.id)}>Update</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default AdminPage;
