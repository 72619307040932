import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class BookItem extends Component {
  render() {
    const { title } = this.props.book;
    return (
      <div>
        <h2>{title.rendered}</h2>
      </div>
    );
  }
}

export default BookItem;