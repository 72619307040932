import React from 'react';
import { Link } from 'react-router-dom';
import HouseDetail1 from './Krzyki';

 import HouseDetail2 from './DomKryniczno';
// import HouseDetail3 from './HouseDetail3';
import Kin from './images/card/wd.jpg';
import W3 from './images/card/w30.webp';
import W4 from './images/card/w5.jpg';
import W5 from './images/card/w6.jpg';
import W6 from './images/card/w7.webp';
import W8 from './images/card/w8.webp';
import W16 from './images/card/w16.jpg';
import W17 from './images/card/w17.jpg';
import W18 from './images/card/w18.jpg';
import W19 from './images/card/w19.jpg';
import W20 from './images/card/w20.jpg';
import W21 from './images/card/w9.webp';
import W22 from './images/card/W26.jpg';
import W23 from './images/card/w30.jpg';
import W24 from './images/card/w28.jpg';

export default function Card1() {
    return (
 
        <section className="box1" id='box1' style={{ backgroundColor: 'lightGray' }}>

                  <div style={{textAlign: 'center',margin: '0 auto',}}><h4 className="ho">Sprawdź niektóre z naszych ofert</h4></div>  

            <div className="container1" style={{paddingTop: '30px'}}>
                <div className="box6">
                    <Link to="/DomKryniczno" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={Kin}
                            alt="Los Angeles"
                            class="d-block"
                            style={{ width: '100%' }}
                        />
                        <p>Dom Kryniczno</p></Link>
                    
                </div>
                <div className="box6">
                    <Link to="/Krzyki" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W3}
                            alt="Los Angeles"
                            class="d-block"
                            style={{ width: '100%' }}
                        />
                        <p >Mieszkanie Wrocław Krzyki, Wrocław, dolnośląskie</p>
                    </Link>
                </div>
                <div className="box6">
                    <Link to="/Trzebnica" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                        <img
                            src={W4}
                            alt="Los Angeles"
                            class="d-block"
                            style={{ width: '100%' }}
                        />
                        <p>Dom Trzebnica Trzebnica, dolnośląskie</p>
                    </Link>
                </div>
            </div>
           
            
        </section>
    );
}