import React from 'react';

const GoogleMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2622.020888848089!2d16.1976448!3d51.3941222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470f6f68accd28a7%3A0xadcd2597bf79268d!2sGenera%C5%82a%20W%C5%82adys%C5%82awa%20Sikorskiego%201%2C%2059-300%20Lubin!5e0!3m2!1sen!2sin!4v1675550017525!5m2!1sen!2sin"
        style={{ width: '100%', height: '548px',paddingTop:'50px' }}
      ></iframe>
    </div>
  );
};

export default GoogleMap;