import React from 'react';
import { Carousel } from 'react-bootstrap';

import W6 from './images/w61/w6.jpg';
import W61 from './images/w61/w61.webp';
import W62 from './images/w61/w612.jpg';
import W63 from './images/w61/w6123.webp';
import W64 from './images/w61/w61234.webp';



import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail4() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Dom, ul. Milicka </h1>
                <p>Milicka, Trzebnica, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W6}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W61}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W62}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W63}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W64}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                   
                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane: 20 marca 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 850 000 zł</span></strong></p>
                    <p>Obszar:<strong><span style={{ color: 'brown' }}> 160 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}> 5 312,50 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Milicka, Trzebnica, dolnośląskie
</span></strong></p>
                    <p>Warto zadzwonić:<strong>Wysokie zainteresowanie</strong></p>
                    <p>Rodzaj domu:<strong>dom wolnostojący</strong></p>
                    <p>Liczba pokoi:<strong>5</strong></p>
                    <p>Powierzchnia działki:<strong> 10 000 m² </strong></p>
                    <p>Rok budowy: <strong>2024</strong></p>
                    <p>Miejsce postojowe:<strong>garaż w bryle budynku</strong></p>

                </div>

            </section>
           
        </div>
        <Footer />
    </>
    );
}