import React from 'react';
import { Carousel } from 'react-bootstrap';

import W5 from './images/w51/w5.jpg';
import W51 from './images/w51/w51.jpg';
import W52 from './images/w51/w512.jpg';
import W53 from './images/w51/w5123.jpg';
import W54 from './images/w51/w51234.webp';


import W55 from './images/w51/w5123456.webp';
import W56 from './images/w51/w51234567.webp';

import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail3() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Dom Trzebnica </h1>
                <p>Trzebnica, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W5}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W51}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W52}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W53}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W54}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W55}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W56}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Szczegóły domu</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 449 000 zł</span></strong></p>

                    <p>Obszar:<strong> <span style={{ color: 'brown' }}> 100 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}> 4 490 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Trzebnica, dolnośląskie
</span></strong></p>
                    <p>Nie zwlekaj z kontaktem:<strong> bardzo wysokie zainteresowanie</strong></p>
                    <p>Rodzaj domu:<strong> dom wolnostojący</strong></p>
                    <p>Liczba pokoi:<strong> 4</strong></p>
                    <p>Powierzchnia działki:<strong> 1150 m² </strong></p>
                    <p>Rok budowy: <strong>2022</strong></p>
                    <p>Miejsce postojowe:<strong> na podjeździe</strong></p>

                </div>

            </section>
            
        </div>
        <Footer />
    </>
    );
}