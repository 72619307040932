import React from 'react';
import { Carousel } from 'react-bootstrap';
import W11 from './images/w11/w11.jpg';
import W12 from './images/w11/w12.jpg';
import W13 from './images/w11/w13.jpg';
import W14 from './images/w11/w14.jpg';

import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail9() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Działka budowlana, ul. Wrocławska</h1>
                <p>Wrocławska, Nowa Wieś Wrocławska, dolnośląskie
</p>

                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W11}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W12}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W13}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W14}
                            alt="Third slide"
                        />

                    </Carousel.Item>

                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane:  23 stycznia 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 500 000 zł</span> </strong></p>
                    <p>Obszar:<strong> <span style={{ color: 'brown' }}> 277,78 zł/m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}>  1 800 m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Działka budowlana, ul. Wrocławska</span></strong></p>
                    <p>Warto zadzwonić: <strong>wysokie zainteresowanie</strong></p>
                    <p>Rodzaj działki: <strong>budowlana</strong></p>
                    <p>Dojazd: <strong>droga asfaltowa</strong></p>
                    <p>Kształt: <strong>prostokąt</strong></p>
                    <p>Media:<strong> ___</strong></p>
                </div>

            </section>
           
        </div>
        <Footer />
    </>
    );
}