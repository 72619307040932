
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Add this line to import Firestore
import { getAuth } from "firebase/auth"; // Import getAuth function for authentication
// Your web app's Firebase configuration
// For Firebase JS SDK v9.0.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyDzRKGWWBCs7HVQyqtB8_42yDRH7xKUDQA",
  authDomain: "west24-30189.firebaseapp.com",
  projectId: "west24-30189",
  storageBucket: "west24-30189.appspot.com",
  messagingSenderId: "525927550001",
  appId: "1:525927550001:web:6e4b2503035cb9a4c51910",
  measurementId: "G-BEMZ6G19BG"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Get the Auth instance for authentication
const db = getFirestore(app); // Get the Firestore instance

export { app, analytics, auth, db }; // Export Firebase app, analytics, auth, and Firestore instance
