import React from 'react';
import { Carousel } from 'react-bootstrap';

import W1 from './images/w17/w1.jpg';
import W2 from './images/w17/w2.jpg';
import W3 from './images/w17/w3.jpg';
import W4 from './images/w17/w4.jpg';
import W5 from './images/w17/w5.jpg';

import W6 from './images/w17/w6.jpg';
import W7 from './images/w17/w7.jpg';
import W8 from './images/w17/w8.jpg';
import W9 from './images/w17/w9.jpg';
import W10 from './images/w17/w10.jpg';
import W11 from './images/w17/w11.jpg';
import W12 from './images/w17/w12.jpg';
import W13 from './images/w17/w13.jpg';
import W14 from './images/w17/w14.jpg';
import W15 from './images/w17/w15.jpg';
import W16 from './images/w17/w16.jpg';
import W17 from './images/w17/w17.jpg';
import W18 from './images/w17/w18.jpg';
import W19 from './images/w17/w19.jpg';


import Navbar from './Navbar';
import Footer from './footer';


export default function KrzykiWrocław2() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Dom Wrocław</h1>
                <p>Karłowice, Wrocław, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W1}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W2}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W3}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W4}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W5}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W6}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W7}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W8}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W9}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W10}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W11}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W12}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W13}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W14}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W15}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W16}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W17}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W18}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W19}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    


                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane:  30 kwietnia 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 2 290 000 zł</span></strong></p>
                    <p>Obszar: <strong><span style={{ color: 'brown' }}>   280 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}>   8 178,57 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Krzyki, Wrocław, dolnośląskie
</span></strong></p>
                    <p>Nie zwlekaj z kontaktem <strong>bardzo wysokie zainteresowanie</strong></p>
                    <p>Rodzaj domu: <strong>dom wolnostojący</strong></p>
                    <p>Liczba pokoi: <strong>6</strong></p>
                    <p>Rok budowy: <strong>1988</strong></p>
                    <p>Powierzchnia działki:<strong>480 m²</strong></p>
                    <p>Miejsce postojowe: <strong>tak</strong></p>
                   


                </div>

            </section>

        </div>
        <Footer />
    </>
    );
}