import React from 'react';
import { Carousel } from 'react-bootstrap';
import W1 from './images/w13/w1.jpg';
import W2 from './images/w13/w2.jpg';
import W3 from './images/w13/w3.jpg';
import W4 from './images/w13/w4.jpg';
import W5 from './images/w13/w5.jpg';
import W6 from './images/w13/w6.webp';

import Navbar from './Navbar';
import Footer from './footer';


export default function Ceglana() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Działka, ul. Ceglana</h1>
                <p>Ceglana, Swojczyce, Wrocław, dolnośląskie</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W1}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W2}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W3}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W4}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W5}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W6}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                   
                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Szczegóły domu</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 4 385 100 zł</span></strong></p>
                    <p>Obszar:<strong> <span style={{ color: 'brown' }}> 14 617 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}> 300 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}>Ceglana, Swojczyce, Wrocław, dolnośląskie</span></strong></p>
                    <p>Warto zadzwonić:<strong> wysokie zainteresowanie</strong></p>                   
                    <p>Zaktualizowane: <strong> 30 kwietnia 2024</strong></p>
                    <p>Dojazd:<strong> droga asfaltowa</strong></p>
                    <p>Kształt:<strong>prostokąt</strong></p>
                    <p>Media:<strong>prąd</strong></p>
                </div>

            </section>

        </div>
        <Footer />
    </>
    );
}