import React from 'react';
import { Carousel } from 'react-bootstrap';
import W32 from './images/w32/w32.webp';
import W321 from './images/w32/w321.webp';
import W3212 from './images/w32/w3212.webp';
import W1 from './images/w32/w1.jpg';
import W2 from './images/w32/w2.jpg';
import W3 from './images/w32/w3.jpg';

import Navbar from './Navbar';
import Footer from './footer';


export default function DomKryniczno() {
    return (<>

    <Navbar />

        <div>
         
            <section className='house1'>
               
                <h1 className="hh" style={{ paddingLeft: '40px' }}>Dom Kryniczno</h1>
                
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W1}
                        alt="First slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W2}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W3}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W32}
                        alt="First slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W321}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W3212}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                
            </Carousel>
            <div className='detail'>
            <h4 className="h2h" style={{paddingLeft:'40px',color: 'black'}}>Szczegóły domu</h4>
                    <p>Cena:<strong> <span style={{ color: 'brown' }}> 849 000 zł </span> </strong></p>
                    <p>Obszar:<strong> <span style={{ color: 'brown' }}> 100 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}> 8 490 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Kryniczno, dolnośląskie</span></strong></p>
                <p>Warto zadzwonić:<strong> wysokie zainteresowanie</strong></p>
                <p>Rodzaj domu:<strong> __</strong></p>
                <p>Liczba pokoi:<strong> 4</strong></p>
                    <p>Rok budowy:<strong> 2023</strong></p>
                <p>Miejsce postojowe:<strong> ___</strong></p>
                </div>

            </section>
            
        </div>
        <Footer />
        </>
    );
}