import React from 'react';
import { Carousel } from 'react-bootstrap';

import W7 from './images/w7/w7.webp';
import W71 from './images/w7/w71.jpg';
import W73 from './images/w7/w72.jpg';
import W74 from './images/w7/w73.jpg';
import W75 from './images/w7/w74.jpg';
import W76 from './images/w7/w76.webp';


import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail6() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Działka budowlana, ul. Błękitna </h1>
                <p>Błękitna, Oleszna, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W7}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W71}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W73}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W74}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W75}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W76}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    

                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane: 16 kwietnia 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 160 000 zł</span></strong></p>
                    <p>Obszar:<strong> <span style={{ color: 'brown' }}>  1 500 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}>  106,67 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}>Żernicka, Żerniki, Wrocław, dolnośląskie
</span></strong></p>
                    <p>Rodzaj działki:<strong> budowlana</strong></p>
                    <p>Dojazd:<strong>droga asfaltowa</strong></p>
                    <p>Kształt: <strong>prostokąt </strong></p>
                    <p>Media:<strong>prąd, woda</strong> </p>
                   


                </div>

            </section>
            
        </div>
        <Footer />
    </>
    );
}