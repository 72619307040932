import React from 'react';
import { Carousel } from 'react-bootstrap';
import W31 from './images/w31/w31.webp';
import W312 from './images/w31/w312.webp';
import W3123 from './images/w31/w3123.webp';
import W3 from './images/w31/w3.webp';
import W31234 from './images/w31/w31234.webp';
import W312345 from './images/w31/w312345.webp';
import W3123456 from './images/w31/w312345678.webp';
import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail1() {
    return (<>

    <Navbar />

        <div>
         
            <section className='house1'>
               
                <h1 className="hh" style={{ paddingLeft: '40px' }}>Mieszkanie Wrocław</h1>
                <p>Krzyki, Wrocław, dolnośląskie</p>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W3}
                        alt="First slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W31}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W312}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W3123}
                        alt="First slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W31234}
                        alt="Third slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 house-carousel-image"
                        src={W312345}
                        alt="Third slide"
                    />

                </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W3123456}
                            alt="Third slide"
                        />

                    </Carousel.Item>
            </Carousel>
            <div className='detail'>
            <h4 className="h2h" style={{paddingLeft:'40px',color: 'black'}}>Szczegóły domu</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 895 300 zł</span></strong></p>
                    <p>Obszar:<strong> <span style={{ color: 'brown' }}> 70 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}> 12 790 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Kryniczno, Poland</span></strong></p>
                        <p>Warto zadzwonić:<strong> wysokie zainteresowanie</strong></p>
                            <p>Piętro:<strong> parter/1</strong></p>
                                <p>Liczba pokoi:<strong>3</strong></p>
                                    <p>Rok budowy: <strong>2024</strong></p>
                                        <p>Miejsce parkingowe:<strong> ___</strong></p>
                                            <p>Stan mieszkania:<strong>do wykończenia</strong></p>
                </div>

            </section>
          
        </div>
         <Footer /> 
        </>
    );
}