import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import logo from "./images/card/logo.jpg";
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';


import { SocialIcon } from 'react-social-icons';




export default function Footer() {
  return (
  
    <>
    
    <div style={{position: 'fixed',
  top: '50%',
  webkitTransform: 'translateY(-50%)',
  msTransform: 'translateY(-50%)',
  transform: 'translateY(-50%)',
  right: 0,
}}>
  {/* <SocialIcon url="https://instagram.com/" style={{ display: 'block', height: 35, width: 35,marginRight:5,marginBottom:5,
  }}/> */}

        <SocialIcon url="https://m.facebook.com/photo.php?fbid=122103104570270165&set=a.122103104666270165&type=3" style={{display:'block', height: 35, width: 35,marginRight:5 }}/>
  
</div>

   
    <MDBFooter style={{backgroundColor:'black'}} className='text-center text-lg-start text-muted'>
      <section  style={{backgroundColor:'black'}} className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block' >
          <span style={{color: '#ddd',}}>Dołącz do nas w mediach społecznościowych:</span>
        </div>

        <div>
          <div>
    {/* <SocialIcon url="https://instagram.com/" style={{ height: 35, width: 35,marginRight:5, }}/> */}

  {/* <SocialIcon url="" style={{ height: 35, width: 35,marginLeft:5 }}/> */}
   </div>
        </div>
      </section>

      <section style={{backgroundColor:'black', color:'white'}}>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='6' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                
                <Link to="/Home" className="navbar-logo">
                
                    <img src={logo} alt="logo" style={{ paddingRight: '50px', maxWidth: '70%',
              height: 'auto'}}/> 
          
        </Link>
              </h6>
             
            </MDBCol>

           
            <MDBCol md='4' lg='6' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase text-center fw-bold mb-4' style={{ color: '#ddd' }}>Agnieszka Beuch</h6>
             <ul>
            <li style={{textAlign: 'center', color: '#ddd'}}>
                <MDBIcon style={{ color: '#ddd' }} icon='home' className='me-2' />
             
Ul. gen. Sikorskiego 1 Lubin, Poland
              </li>
              <li style={{textAlign: 'center', color: '#ddd'}}>
                <MDBIcon style={{ color: '#ddd' }} icon='envelope' className='me-3' />nieruchomości@west24.pl
              {/* npm  */} 
              </li>
              <li style={{textAlign: 'center', color: '#ddd' }}>
                      <MDBIcon style={{ color: '#ddd' }} icon='phone' className='me-3' /> +48 690 206 931
              </li>
              </ul>
            </MDBCol>
            
          </MDBRow>
        </MDBContainer>
      </section>
        <div className='text-center text-#244563 p-4' style={{ backgroundColor: '#dddddd', textDecoration:'none'}}>
        
        
       
          <div class="container" >
  <div className="column" style={{textAlign:'left',paddingLeft:0,}}>
   
    <p>© 2024 &nbsp;
        <a className='text-reset ' style={{textDecoration:'none',textAlign:'left'}} href='Home.js'>
                  WEST24
        
  
        </a></p>
  </div>
            <div className="column" style={{ textAlign: 'right', paddingRight: 0, paddingLeft: 0, }}>
             
    <p><a className='text-reset ' style={{textDecoration:'none',textAlign:'right'}} >
          WEST24 &nbsp; <span> |</span>
        </a>
       &nbsp; DESIGN BY &nbsp;
        <a className='text-reset ' style={{textDecoration:'none'}} >
          KINDE TESFAYE
        </a></p>
  </div>
</div>
      </div>
      
     
    </MDBFooter>
    </>

  );
}
