import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { db } from './firebase'; // Make sure you have the db instance

function AdminDashboard() {
  const [listings, setListings] = useState([]);
  const [newListing, setNewListing] = useState({
    title: '',
    description: '',
    price: ''
    // Add more fields as needed
  });

  useEffect(() => {
    // Fetch listings from Firestore
    const unsubscribe = db.collection('listings').onSnapshot(snapshot => {
      const listingsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setListings(listingsData);
    });

    return () => unsubscribe();
  }, []);

  const handleAddListing = async () => {
    try {
      await db.collection('listings').add(newListing);
      setNewListing({ title: '', description: '', price: '' });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteListing = async (id) => {
    try {
      await db.collection('listings').doc(id).delete();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <button onClick={() => auth.signOut()}>Logout</button>
      
      {/* Add New Listing Form */}
      <form onSubmit={handleAddListing}>
        <input type="text" value={newListing.title} onChange={(e) => setNewListing({ ...newListing, title: e.target.value })} placeholder="Title" />
        <input type="text" value={newListing.description} onChange={(e) => setNewListing({ ...newListing, description: e.target.value })} placeholder="Description" />
        <input type="text" value={newListing.price} onChange={(e) => setNewListing({ ...newListing, price: e.target.value })} placeholder="Price" />
        <button type="submit">Add Listing</button>
      </form>

      {/* Display Listings */}
      <ul>
        {listings.map(listing => (
          <li key={listing.id}>
            <h3>{listing.title}</h3>
            <p>{listing.description}</p>
            <p>Price: {listing.price}</p>
            <button onClick={() => handleDeleteListing(listing.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminDashboard;
