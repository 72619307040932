import React from 'react';
import { Carousel } from 'react-bootstrap';

import W8 from './images/w8/w8.webp';
import W81 from './images/w8/w82.webp';
import W82 from './images/w8/w83.webp';
import W83 from './images/w8/w84.webp';
import W84 from './images/w8/w85.webp';

import W85 from './images/w8/w86.webp';
import W86 from './images/w8/w87.webp';
import W87 from './images/w8/w88.webp';
import W88 from './images/w8/w89.webp';
import W89 from './images/w8/w810.webp';
import W810 from './images/w8/w811.webp';
import W811 from './images/w8/w812.webp';
import W812 from './images/w8/w813.webp';

import Navbar from './Navbar';
import Footer from './footer';


export default function HouseDetail5() {
    return (<>

        <Navbar />

        <div>

            <section className='house1'>

                <h1 className="hh" style={{ paddingLeft: '40px' }}>Mieszkanie, ul. Żernicka </h1>
                <p>Żernicka, Żerniki, Wrocław, dolnośląskie
</p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W8}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W81}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W82}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W83}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W84}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W85}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W86}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W87}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W88}
                            alt="First slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W89}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W810}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W811}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 house-carousel-image"
                            src={W812}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                   

                </Carousel>
                <div className='detail'>
                    <h4 className="h2h" style={{ paddingLeft: '40px', color: 'black' }}>Zaktualizowane: 20 marca 2024</h4>
                    <p>Cena:<strong><span style={{ color: 'brown' }}> 559 000 zł</span></strong></p>
                    <p>Obszar: <strong><span style={{ color: 'brown' }}> 40,40 m²</span></strong></p>
                    <p>Cena za m²:<strong><span style={{ color: 'brown' }}>  13 836,63 zł/m²</span></strong></p>
                    <p>Lokalizacja:<strong><span style={{ color: 'brown' }}> Żernicka, Żerniki, Wrocław, dolnośląskie
</span></strong></p>
                    <p>Piętro:<strong> 2/2</strong></p>
                    <p>Warto zadzwonić: <strong>Wysokie zainteresowanie</strong></p>
                    <p>Rodzaj domu: <strong>dom wolnostojący</strong></p>
                    <p>Liczba pokoi: <strong>1</strong></p>
                    <p>Rok budowy: <strong>2007</strong></p>
                    <p>Miejsce parkingowe: <strong>przynależne na terenie ogrodzonym</strong></p>
                    <p>Stan mieszkania:<strong>bardzo dobry</strong></p>


                </div>

            </section>
          
        </div>
        <Footer />
    </>
    );
}