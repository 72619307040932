// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
// import ImageSection from './pages/imageSection';

import Home from './pages/Home';
import About from './pages/Onas';
 import Oferta from './pages/offer';
 import MapContainer from './pages/contact';
import HouseDeatil from './pages/Krzyki';
import DomKryniczno from './pages/DomKryniczno';
import HouseDeatil3 from './pages/Trzebnica';
import HouseDeatil4 from './pages/Milicka';
import HouseDeatil5 from './pages/Żernicka';
import HouseDeatil6 from './pages/Błękitna';
import HouseDeatil7 from './pages/HouseDetail7';
import HouseDeatil8 from './pages/Kryniczno';
import HouseDeatil9 from './pages/Działkabudowlana';
import KrzykiWrocław1 from './pages/KrzykiWrocław';
import Ceglana from './pages/Ceglana';
import OfiarOświęcimskich from './pages/OfiarOświęcimskich';
import KarłowiceWrocław from './pages/KarłowiceWrocław';
import Potokowa from './pages/Potokowa';
import  KrzykiWrocław2 from './pages/KrzykiWrocław2';
import './App.css';
// import AdminPage from './AdminPage1';
import Books from './components/Books';
import AdminDashboard from './AdminDashboard';
import Login from './login';
import AdminPage from './AdminPage';



const App = () => {
  return (


    <>
      <BrowserRouter>


        <Outlet />
        <Routes>

          <Route exact path="/" element={<Home />} />
           <Route exact path="/offer" element={<Oferta />} />
          <Route exact path="/onas" element={<About />} />
          <Route exact path="/contact" element={<MapContainer />} />
          <Route exact path="/Krzyki" element={<HouseDeatil />} /> 
          <Route exact path="/DomKryniczno" element={<DomKryniczno/>} /> 
          <Route exact path="/Trzebnica" element={<HouseDeatil3 />} /> 
          <Route exact path="/Milicka" element={<HouseDeatil4 />} /> 
          <Route exact path="/Żernicka" element={<HouseDeatil5 />} /> 
          <Route exact path="/Błękitna" element={<HouseDeatil6 />} /> 
          <Route exact path="/HouseDetail7" element={<HouseDeatil7 />} /> 
          <Route exact path="/Kryniczno" element={<HouseDeatil8 />} /> 
          <Route exact path="/Działkabudowlana" element={<HouseDeatil9 />} /> 
          <Route exact path="/KrzykiWrocław" element={<KrzykiWrocław1 />} /> 
          <Route exact path="/Ceglana" element={<Ceglana />} /> 
          <Route exact path="/OfiarOświęcimskich" element={< OfiarOświęcimskich />} /> 
          <Route exact path="/KarłowiceWrocław" element={< KarłowiceWrocław />} />
           <Route exact path="/Potokowa" element={< Potokowa />} />
           <Route exact path="/KrzykiWrocław2" element={< KrzykiWrocław2 />} />
          <Route path='*' element={<Navigate to='/' />} />
{/* <Route path="/admin" element={<AdminPage />} /> */}
<Route path="/Books" element={<Books />} />
<Route path="/AdminDashboard" element={<AdminDashboard />} />
<Route path="/AdminPage" element={<AdminPage />} />
<Route path="/login" element={<Login />} />


        </Routes>

      </BrowserRouter>
    </>
  );
};

export default App;
