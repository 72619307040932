import React from 'react';
import image from './images/card/aga1.jpg';
import Footer from './footer';
import Navbar from './Navbar';
import a from './images/card/about.jpg';
import Card1 from './card3';

const AboutUs = () => {
    return (
        <> < Navbar /> <section id="ab" className="responsive-section">
  <img src={a} alt="About Us" className="responsive-image" />
  <div className="text-overlay" >
    <p className="pt">Jesteśmy do Twojej dyspozycji od 2014 roku</p>
  </div>
</section>
        <section className="containerA ">
            <h2
                style={{
                    textAlign: 'center',
                    margin: '0 auto',
                    paddingTop: '30px',
                    paddingBottom: '40px',
                    color:'#ddd',
                    fontWeight: 'semiBold',
                }}>O nas</h2>
            <div className="row clearfix" style={{paddingBottom: '30px'}}>
                <div className="col-md-4">
                    <img src={image} alt="About Us" className="img-fluid float-right" id='i'/>
                </div>
                <div className="col-md-8">
                    <p>Witamy w naszej firmie! Mam na imię Agnieszka i jestem dumnym członkiem
                        naszego zespołu. Nasza firma jest zaangażowana w pomoc ludziom w znalezieniu
                        idealnego domu, który odpowiada ich potrzebom i stylowi życia.</p>
                    <p>W WEST24 rozumiemy, że zakup domu może być trudnym zadaniem. Dlatego jesteśmy
                        tutaj, aby poprowadzić Cię przez cały proces, od poszukiwania wymarzonego domu
                        po negocjowanie najlepszej dla Ciebie oferty.</p>
                    <p>Mamy zespół doświadczonych i oddanych profesjonalistów, którzy posiadają
                        dogłębną wiedzę na temat branży nieruchomości. Nasz zespół z pasją zapewnia
                        naszym klientom najlepszą możliwą obsługę. Wierzymy w budowanie długotrwałych
                        relacji z naszymi klientami opartych na zaufaniu i wzajemnym szacunku.</p>
                    <p>Z dumą oferujemy szereg nieruchomości, które zaspokajają różne budżety i
                        potrzeby. Niezależnie od tego, czy szukasz przytulnego apartamentu, czy
                        przestronnej willi, mamy coś, co spełni Twoje wymagania. Nasze nieruchomości
                        znajdują się w najbardziej pożądanych lokalizacjach i są zbudowane zgodnie z
                        najwyższymi standardami.</p>
                    <p>W WEST24 naszym celem jest, aby proces zakupu nieruchomości był jak
                        najbardziej płynny i bezproblemowy. Dążymy do zapewnienia światowej klasy usług
                        naszym klientom i staramy się przekraczać ich oczekiwania.
                    </p>
                    <p>Dziękujemy za rozważenie naszej firmy w celu zaspokojenia potrzeb związanych
                        z nieruchomościami. Z niecierpliwością czekamy, aby być częścią Twojej podróży
                        do posiadania wymarzonego domu.</p>
                </div>
            </div>
       <Card1/>
       
       
        </section>



         < Footer /> </>
    );
}

export default AboutUs;
