import React from 'react';

const ContactInfo = () => {
  return (
    <div className="p-4 text-white rounded" id='info' style={{ backgroundColor: '#000020', height: '300px' }}>
      <h3 className="text-center"style={{color:'#ddd'  }}>Informacje kontaktowe</h3>
      <br />
      <p style={{ fontWeight: 'bold',color:'#ddd' }}>AGNIESZKA BEUCH</p>
      <p style={{color:'#ddd'  }}><i className="fa fa-phone" /> : 690 206 931</p>
      <p style={{color:'#ddd'  }} ><i className="fa fa-envelope" /> : nieruchomości@west24.pl</p>
      <p style={{color:'#ddd'  }}><i className="fa fa-map-marker-alt" /> : Ul. gen. Sikorskiego 1 Lubin</p>
    </div>
  );
};

export default ContactInfo;